/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import dashboard from "./dashboard"
// import apps from './apps'
import pages from "./pages"
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
// import others from './others'
import store from "@/store"
const routesArray = []
if (store.getters["auth/getRole"] === "admin") {
  routesArray.push(...dashboard)
  routesArray.push(...pages)
  //   routesArray.push(...others)
  //   routesArray.push(...chartsAndMaps)
  //   routesArray.push(...uiElements)
  //   routesArray.push(...formAndTable)
}
if (store.getters["auth/getRole"] === "user") {
  routesArray.push(...appsAndPages)
}

// Array of sections
const data = [...routesArray]
export default data
